<template>
  <div 
    v-if="gif?.url"
    data-testid="badgeGif"
    role="img"  
    name="badgeGif"  
    class="badge_gif--container">
    <BadgeGifLink
      v-if="promoLink && !isIaChat"  
      :description="gif.description"
      :srcImage="getGifUrl"
      :promoBadgeUrl="promoLink"
      :windowType="isPlp ? gif.promo_url_badge_image_plp_windows_type: gif.promo_url_badge_image_pdp_windows_type"
      @promoSelected="setPromotionIframeAsVisible" /> 

    <img
      v-else
      :alt="gif.description"
      :title="gif.description"
      :src="getGifUrl" />
  </div>

  <ModalWithIframe
    v-if="showPromotionIframe"
    :iframeURL="iframeUrl"
    @closeModal="closeModal" />
</template>
<script>
  import { inject } from 'vue';
  import BadgeGifLink from './BadgeGifLink.vue';

  export default {
    components: { 
      BadgeGifLink,
      ModalWithIframe: defineAsyncComponent(() => import('CommonComponents/Iframe/ModalWithIframe.vue')), 
    },
    props: {
      gif: { 
        type: Object, 
        required: true 
      },
      fromPDP: {
        type: Boolean,
        default: false
      },
      isIaChat: {
        type: Boolean,
        default: false,
        required: false,
      },
      isPlp: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    setup(props) {
      const assetsBase = inject('assetsBase');
      const iframeUrl = ref('');
      const showPromotionIframe = ref(false);
      
      const getGifUrl = computed(() => {
        const gif = props.gif;
        if(!gif){
          return;
        }
        if (gif.type === 'url_img') {
          return gif.url;
        }
        return `${assetsBase}${gif.url}`;
      });

      const promoLink = computed(() => {
        const gif = props.gif;
        if(!gif){
          return;
        }
        return props.fromPDP ? gif.promo_url_badge_image_pdp : gif.promo_url_badge_image_plp
      });

      function closeModal() {
        showPromotionIframe.value = false;
      }

      function setPromotionIframeAsVisible(iframeURL) {
        iframeUrl.value = iframeURL;
        showPromotionIframe.value = true;
      }

      return { 
        assetsBase, 
        getGifUrl, 
        iframeUrl,
        promoLink,
        showPromotionIframe,
        closeModal,
        setPromotionIframeAsVisible,
       };
    },
  };
</script>
